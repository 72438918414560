<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LOAD OR SERIES</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-col
              md="4"
              cols="12"
            ></v-col>
            <v-row>
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="transmital_no"
                  label="Transmital #"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>

              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="stab_no"
                  label="Stab #"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="or_series"
                  label="OR Series"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="or_address"
                  label="OR Address"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="date_release"
                  dense
                  label="Date"
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  type="date"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="collector_id"
                  dense
                  outlined
                  label="Collector"
                  :items="collectors"
                  item-value="id"
                  item-text="full_name"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-col>
            </v-row>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="load_or_series"
                v-if="!saving"
              >
                Save changes
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    created() {
      this.init()
    },
    data() {
      return {
        saving: false,
        alert: false,
        alert_message: '',

        collectors: [],
        collector_id: 0,
        transmital_ors_id: 0,
        transmital_no: 0,
        stab_no: 0,
        or_series: '',
        or_address: '',
        date_release: '',
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('or_series', ['get_load_or_series_available', 'load_or_series_to_collector']),
      init() {
        const data = new FormData()
        data.append('branch_id', this.branch_id);
        this.get_load_or_series_available(data)
          .then(response => {
            this.collectors = response.data[0].collectors_items
            if (response.data[0].current_empty_or != null) {
              this.transmital_ors_id = response.data[0].current_empty_or.id
              this.transmital_no = response.data[0].current_empty_or.transmital_no
              this.stab_no = response.data[0].current_empty_or.stab_no
              this.or_series = response.data[0].current_empty_or.start + '-' + response.data[0].current_empty_or.end
              this.or_address = response.data[0].current_empty_or.address
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
      reset() {
        this.saving = false
        this.alert = false
        this.alert_message = ''

        this.collectors = []
        this.collector_id = 0
        this.transmital_ors_id = 0
        this.transmital_no = 0
        this.stab_no = 0
        this.or_series = ''
        this.or_address = ''
        this.date_release = ''
      },
      load_or_series() {
        this.saving = true
        this.alert = false
        if (this.transmital_ors_id === 0) {
          this.alert = true
          this.alert_message = 'Please request to Head Office (TO LOAD TRANSMITAL OR SERIES)'
          this.saving = false
        } else {
          if (this.$refs.form.validate()) {
            const data = new FormData()
            data.append('branch_id', this.branch_id);
            data.append('transmital_ors_id', this.transmital_ors_id);
            data.append('collector_id', this.collector_id);
            data.append('date_release', moment(this.date_release, 'YYYY-MM-DD').format('MMMM D, YYYY'));
            this.load_or_series_to_collector(data)
              .then(response => {
                this.change_snackbar({
                  show: true,
                  color: 'success',
                  text: response.data,
                })
                this.init()
                this.reset()
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                console.log(error)
                this.saving = false
              })
          } else {
            this.alert = true
            this.alert_message = 'Please Fill up the field/s'
            this.saving = false
          }
        }
      }
    }
  }
</script>
